import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';

import {
  Button,
} from '@mui/material';

// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DropzoneArea } from 'react-mui-dropzone';
// import EditItemView from './components/EditItemView';
import { useTranslation } from '../common/components/LocalizationProvider';
import SettingsMenu from './components/SettingsMenu';
import { useCatch } from '../reactHelper';

import PageLayout from '../common/components/PageLayout';

const BulkuploadPage = () => {
  const t = useTranslation();

  // const [item, setItem] = useState();

  const [nameFileToProcess, setNameFileToProcess] = useState(null);
  const [data, setData] = useState([]);

  const [result, setResult] = useState([]);

  // const [loading, setLoading] = useState(true);

  // const [groups, setGroups] = useState([]);

  // eslint-disable-next-line eqeqeq
  const isNeedUpdateData = (desdeExcel, desdeSistema) => !(desdeExcel.attributes.IMEI == desdeSistema.attributes.IMEI
       // eslint-disable-next-line eqeqeq
       && desdeExcel.attributes['N° Bin'] == desdeSistema.attributes['N° Bin']
       // eslint-disable-next-line eqeqeq
       && desdeExcel.attributes.Empresa == desdeSistema.attributes.Empresa
       // eslint-disable-next-line eqeqeq
       && desdeExcel.groupId == desdeSistema.groupId
       // eslint-disable-next-line eqeqeq
       && desdeExcel.name == desdeSistema.name
       // eslint-disable-next-line eqeqeq
       && desdeExcel.uniqueId == desdeSistema.uniqueId
       // eslint-disable-next-line eqeqeq
       && desdeExcel.phone == desdeSistema.phone
       // eslint-disable-next-line eqeqeq
       && desdeExcel.contact == desdeSistema.contact
       // eslint-disable-next-line eqeqeq
       && desdeExcel.category == desdeSistema.category);

  /* procesamiento con apis */
  const leerExcel = async (dataExcel) => {
    const nombreEmpresa = data[0].Empresa;
    let getGroup = null;
    let idGrupo = null;
    try {
      const getGroupResponse = await fetch(`/api/groups?name=${nombreEmpresa}`, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (getGroupResponse.ok) {
        getGroup = await getGroupResponse.json();
        idGrupo = getGroup[0].id;
      } else {
        throw Error(await getGroupResponse.text());
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }

    dataExcel.forEach(async (itemFila) => {
      const data = {
        attributes: {
          IMEI: itemFila.IMEI,
          'N° Bin': itemFila['N° Bins'],
          Empresa: itemFila.Empresa,
        },
        groupId: idGrupo,
        name: itemFila['N° Bins'],
        uniqueId: itemFila.ID,
        phone: itemFila['N. Linea'],
        contact: itemFila['Observación'],
        category: 'bin',
      };

      let getDevice = null;
      try {
        const getDeviceResponse = await fetch(`/api/devices?uniqueId=${itemFila.ID}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (getDeviceResponse.ok) {
          getDevice = await getDeviceResponse.json();
          if (getDevice.length > 0) {
            const areDistinct = await isNeedUpdateData(data, getDevice[0]);
            if (areDistinct) {
              data.id = getDevice[0].id;
              try {
                const putDeviceResponse = await fetch(`/api/devices/${data.id}`, {
                  method: 'PUT',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(data),
                });
                if (putDeviceResponse.ok) {
                  // const putDevice = await putDeviceResponse.json();
                  setResult((current) => [...current, [`El GPS cuyo id es: ${data.uniqueId} del Bin: ${data.name} actualizado existosamente`]]);
                  // eslint-disable-next-line no-console
                  console.log(`El GPS cuyo id es: ${data.uniqueId} del Bin: ${data.name} actualizado existosamente`);
                } else {
                  throw Error(await putDeviceResponse.text());
                }
              } catch (error) {
                // eslint-disable-next-line no-console
                console.log(`En error de actualizacion: ${error}`);
                // eslint-disable-next-line no-console
                console.log(`El GPS cuyo id es: ${JSON.parse(error.config.data).uniqueId} del Bin: ${JSON.parse(error.config.data).name} no se ha podido actualizar por: ${error.response.data.split(':')[0]}`);
              }
            } else {
              setResult((current) => [...current, [`El GPS cuyo id es: ${data.uniqueId} del Bin: ${data.name} no requiere actualización.`]]);
              // eslint-disable-next-line no-console
              console.log(`El GPS cuyo id es: ${data.uniqueId} del Bin: ${data.name} no requiere actualización.`);
            }
          } else {
            const config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: '/api/devices',
              headers: {
                'Content-Type': 'application/json',
              },
              data,
            };
            axios(config)
              .then((response) => {
                setResult((current) => [...current, [`El GPS cuyo id es: ${response.data.uniqueId} del Bin: ${response.data.name} creado existosamente`]]);
                // eslint-disable-next-line no-console
                console.log(`El GPS cuyo id es: ${response.data.uniqueId} del Bin: ${response.data.name} creado existosamente`);
              })
              .catch((error) => {
                // eslint-disable-next-line no-console
                setResult((current) => [...current, [`El GPS cuyo id es: ${JSON.parse(error.config.data).uniqueId} del Bin: ${JSON.parse(error.config.data).name} no se ha podido crear por: ${error.response.data.split(':')[0]}`]]);
                // eslint-disable-next-line no-console
                console.log(`El GPS cuyo id es: ${JSON.parse(error.config.data).uniqueId} del Bin: ${JSON.parse(error.config.data).name} no se ha podido crear por: ${error.response.data.split(':')[0]}`);
              });
          }
        } else {
          throw Error(await getDeviceResponse.text());
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    });
  };

  /* fin procesamientos con APIS */

  /* useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/groups');
      if (response.ok) {
        setGroups(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);
  */

  const handleFileUpload = useCatch(async (files) => {
    if (files.length > 0) {
      setResult([]);
      const reader = new FileReader();
      reader.readAsBinaryString(files[0]);
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parseData = XLSX.utils.sheet_to_json(sheet, {
          header: 2,
        });
        setNameFileToProcess(files[0].path);
        setData(parseData);
      };
    }
  });

  const processData = async () => {
    try {
      setResult([]);
      await leerExcel(data);
      setData([]);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error while processing file:', error);
    }
  };

  // const validate = () => item && item.name;

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'sharedDrivers']}>
      {(
        <>
          <h1>Carga masiva de dispositivos</h1>
          <DropzoneArea
            dropzoneText={t('sharedDropzoneText')}
            acceptedFiles={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
            filesLimit={1}
            onChange={handleFileUpload}
            showAlerts={false}
          />
          <h2>{nameFileToProcess}</h2>
          <Button variant="contained" sx={{ width: 200, padding: 1, margin: 2 }} onClick={processData} disabled={!data.length}>Procesar Registros</Button>
          { (result.length > 0) && (
            <table className="table">
              <thead>
                <tr>
                  <div>
                    <td>
                      <h3>Logs de procesamiento.  Registros: </h3>
                    </td>
                    <td>
                      <h3>{result.length}</h3>
                    </td>
                  </div>
                </tr>
              </thead>
              <tbody>
                { result.map((row, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={index}>
                    {Object.values(row).map((value, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <td key={index}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </PageLayout>
  );
};

export default BulkuploadPage;
